<template>
	<v-flex mb-5>
		<v-card-title class="title">
			{{ $t('user.titles.list') }}
			<v-icon class="pointer" color="primary" style="margin-left: 10px" @click="dialogHelp = true">help</v-icon>
			<v-spacer />
			<TeamManagerAddBtn :roles="roles" :clients="clients" :is-accountant-admin="isAccountantAdmin" @add="$emit('add', $event)" />
		</v-card-title>
		<v-card-text>
			<v-data-table class="elevation-1" :headers="headers" hide-actions :items="accountants" :loading="loading" :no-data-text="noDataText">
				<template v-slot:progress>
					<v-progress-linear color="primary" indeterminate></v-progress-linear>
				</template>
				<template v-slot:items="props">
					<td class="text-xs-center">{{ props.item.lastname }}</td>
					<td class="text-xs-center">{{ props.item.firstname }}</td>
					<td class="text-xs-center">{{ props.item.email }}</td>

					<td v-if="canUpdateOrDelete(props.item)">
						<v-edit-dialog
							large
							lazy
							:disabled="loading"
							:save-text="$t('actions.save')"
							:cancel-text="$t('actions.cancel')"
							@save="updateRole(props.item)"
						>
							<v-layout align-center justify-space-between row>
								<v-flex>
									<v-icon color="primary">edit</v-icon>
								</v-flex>
								<v-flex pl-2>
									{{ roles.find(r => r.id === props.item.role_id.role_id) ? roles.find(r => r.id === props.item.role_id.role_id).name : '' }}
								</v-flex>
							</v-layout>

							<template v-slot:input>
								<v-select
									v-model="props.item.role_id.role_id"
									:disabled="loading"
									:items="roles"
									item-text="name"
									item-value="id"
									:label="$t('role.name')"
									:readonly="!isAccountantAdmin || props.item.id === user.id"
								></v-select>
							</template>
						</v-edit-dialog>
					</td>
					<td v-else-if="isAccountantAdmin">
						<v-layout align-center justify-start row>
							<v-flex shrink>
								<v-icon color="grey">edit</v-icon>
							</v-flex>
							<v-flex pl-2 shrink>
								{{ roles.find(r => r.id === props.item.role_id.role_id) ? roles.find(r => r.id === props.item.role_id.role_id).name : '' }}
							</v-flex>
						</v-layout>
					</td>
					<td v-else class="text-xs-left">
						{{ roles.find(r => r.id === props.item.role_id.role_id) ? roles.find(r => r.id === props.item.role_id.role_id).name : '' }}
					</td>

					<!-- checkbox:is-group-admin -->
					<td v-if="isGroupAdmin" class="text-xs-center">
						{{ props.item.role_id.is_group_admin ? $t('actions.yes') : $t('actions.no') }}
					</td>

					<td v-if="isAccountantAdmin" class="text-xs-center">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn icon :disabled="loading" v-on="on" @click="sendMail(props.item)">
									<v-icon v-if="!props.item.invited_at" color="primary">add</v-icon>
									<v-icon v-if="!props.item.did_connect && props.item.invited_at" color="primary">redo</v-icon>
									<v-icon v-if="props.item.did_connect && props.item.invited_at" color="primary">loop</v-icon>
								</v-btn>
							</template>
							<span v-if="!props.item.invited_at" v-t="'customers.actions.invite_user'" ml-2 />
							<span v-if="!props.item.did_connect && props.item.invited_at" v-t="'customers.actions.reinvite_user'" ml-2 />
							<span v-if="props.item.did_connect && props.item.invited_at" v-t="'customers.actions.reinitialize_password'" ml-2 />
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn v-if="isAccountantAdmin" :disabled="loading" flat icon v-on="on" @click="$emit('update', props.item)">
									<v-icon color="primary">remove_red_eye</v-icon>
								</v-btn>
							</template>
							<span v-t="'actions.details'" ml-2 />
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn
									v-if="isAccountantAdmin"
									:disabled="!canUpdateOrDelete(props.item) || loading"
									flat
									icon
									v-on="on"
									@click="$emit('delete', props.item)"
								>
									<v-icon color="primary">delete</v-icon>
								</v-btn>
							</template>
							<span v-t="'actions.delete'" ml-2 />
						</v-tooltip>
					</td>
				</template>
			</v-data-table>
		</v-card-text>

		<!-- DIALOG HELP -->
		<WDialog v-model="dialogHelp" max-width="600px" :title="$t('user.titles.access_description')">
			<v-layout>
				<v-data-table :headers="headersHelp" :items="itemsHelp" hide-actions class="elevation-1">
					<template v-slot:items="props">
						<td>{{ props.item.name }}</td>
						<td class="text-xs-center">{{ props.item.admin }}</td>
						<td class="text-xs-center">{{ props.item.collab }}</td>
					</template>
				</v-data-table>
			</v-layout>
		</WDialog>
	</v-flex>
</template>

<script>
import { mapState } from 'vuex'
import TeamManagerModuleGuard from '@/mixins/ModulesGuards/TeamManager/TeamManagerModuleGuard'

export default {
	name: 'TeamManagerLists',
	components: {
		TeamManagerAddBtn: () => ({
			component: import('@/components/TeamManager/TeamManagerAddBtn')
		})
	},
	mixins: [TeamManagerModuleGuard],
	props: {
		accountingFirm: {
			required: true,
			type: Object
		},
		accountingTeam: {
			required: true,
			type: Array
		},
		loading: {
			required: true,
			type: Boolean
		},
		roles: {
			required: true,
			type: Array
		},
		isAccountantAdmin: {
			required: true,
			type: Boolean
		},
		user: {
			required: true,
			type: Object
		},
		clients: {
			required: true,
			type: Array
		}
	},
	data() {
		/* eslint-disable sonarjs/no-duplicate-string */
		return {
			dialogHelp: false,
			headersHelp: [
				{
					text: this.$t('user.dialogs.manage_rights.headers.rights'),
					align: 'left',
					sortable: false,
					value: 'name'
				},
				{ text: this.$t('user.dialogs.manage_rights.headers.admin'), value: 'admin' },
				{ text: this.$t('user.dialogs.manage_rights.headers.collaborator'), value: 'collab' }
			],
			itemsHelp: [
				{
					name: this.$t('user.dialogs.manage_rights.content.add_user'),
					admin: this.$t('user.dialogs.manage_rights.content.yes'),
					collab: this.$t('user.dialogs.manage_rights.content.no')
				},
				{
					name: this.$t('user.dialogs.manage_rights.content.delete_user'),
					admin: this.$t('user.dialogs.manage_rights.content.yes'),
					collab: this.$t('user.dialogs.manage_rights.content.no')
				},
				{
					name: this.$t('user.dialogs.manage_rights.content.change_user_rights'),
					admin: this.$t('user.dialogs.manage_rights.content.yes'),
					collab: this.$t('user.dialogs.manage_rights.content.no')
				},
				{
					name: this.$t('user.dialogs.manage_rights.content.edit_client_files'),
					admin: this.$t('user.dialogs.manage_rights.content.yes'),
					collab: this.$t('user.dialogs.manage_rights.content.no')
				},
				{
					name: this.$t('user.dialogs.manage_rights.content.edit_themes'),
					admin: this.$t('user.dialogs.manage_rights.content.yes'),
					collab: this.$t('user.dialogs.manage_rights.content.no')
				},
				{
					name: this.$t('user.dialogs.manage_rights.content.create_client_folder'),
					admin: this.$t('user.dialogs.manage_rights.content.yes'),
					collab: this.$t('user.dialogs.manage_rights.content.yes')
				},
				{
					name: this.$t('user.dialogs.manage_rights.content.edit_client_folder_info'),
					admin: this.$t('user.dialogs.manage_rights.content.yes'),
					collab: this.$t('user.dialogs.manage_rights.content.yes')
				}
			]
		}
	},
	computed: {
		...mapState({
			userId: state => state.user.id
		}),
		accountants: function () {
			const roles = this.roles
			if (this.accountingTeam) {
				return this.accountingTeam.map(accountant => {
					accountant.role = roles.find(r => r.id === accountant.role_id.role_id) ? roles.find(r => r.id === accountant.role_id.role_id).name : ''
					return accountant
				})
			}
			return []
		},
		headers: function () {
			let headers = [
				{ align: 'center', text: this.$t('last_name'), value: 'lastname' },
				{ align: 'center', text: this.$t('first_name'), value: 'firstname' },
				{ align: 'center', text: this.$t('email'), value: 'email' },
				{ align: 'left', text: this.$t('role.name'), value: 'role', icon: 'edit' }
			]
			if (this.isGroupAdmin) {
				headers.push({ align: 'center', sortable: false, text: this.$t('team-manager.welyb_admin_access'), value: 'is_group_admin' })
			}
			if (this.isAccountantAdmin) {
				headers.push({ align: 'center', sortable: false, text: this.$t('actions.actions') })
			}
			return headers
		},
		isGroupAdmin: function () {
			return this.service.adminGroup()
		},
		noDataText: function () {
			let result
			if (this.loading) {
				result = this.$t('loading_progress')
			} else {
				result = this.$t('user.titles.no_team')
			}
			return result
		}
	},
	methods: {
		sendMail: function (user) {
			let { text: text, title: title } = this.getTextAndTitle(user)
			this.$dialog
				.warning({
					text: text,
					title: title,
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						if (!user.invited_at || (!user.did_connect && user.invited_at)) {
							this.service.sendMail(this.accountingFirm.id, user.id).then(() => {
								this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('actions.mail_sent'))
								this.accountingTeam.map(item => {
									if (item.id == user.id) {
										item.invited_at = Date.now()
									}
									return item
								})
							})
						} else if (user.did_connect && user.invited_at) {
							this.service.resetPasswordSendMail(user.email, [window.location.origin]).then(() => {
								this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('actions.mail_sent'))
							})
						}
					}
				})
		},
		getTextAndTitle: function (user) {
			let text = ''
			let title = ''
			if (!user.invited_at) {
				text = this.$t('customers.actions.are_you_sure_send_mail_invitation', { email: user.email })
				title = this.$t('customers.actions.are_you_sure_title_mail_invitation', { name: user.username })
			} else if (!user.did_connect && user.invited_at) {
				text = this.$t('customers.actions.are_you_sure_send_mail_reinvitation', { email: user.email })
				title = this.$t('customers.actions.are_you_sure_title_mail_reinvitation', { name: user.username })
			} else if (user.did_connect && user.invited_at) {
				text = this.$t('customers.actions.are_you_sure_send_mail_reset_password', { email: user.email })
				title = this.$t('customers.actions.are_you_sure_title_mail_reset_password', { name: user.username })
			}
			return { text: text, title: title }
		},
		updateRole: function (accountant) {
			accountant.role = this.roles.find(role => role.id === accountant.role_id.role_id).key
			this.service
				.updateAccountantRole(this.accountingFirm.id, accountant)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('accounting_firms.messages.right_access_updated'), 0)

					this.accountingTeam.map(accountant => {
						accountant.role = this.roles.find(r => r.id === accountant.role_id.role_id) ? this.roles.find(r => r.id === accountant.role_id.role_id).name : ''
						return accountant
					})
				})
				.finally(() => {
					this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				})
		},
		canUpdateOrDelete: function(item) {
			if (!this.isAccountantAdmin) {
				return false
			}
			if (item?.id == this.user.id) {
				return false
			}

			if(!this.isGroupAdmin) {
				return true
			}
			const user = this.accountants.find(it => it.id == this.user.id)

			if (user.role_id?.is_group_admin) {
				return true
			}

			return !item?.role_id?.is_group_admin
		}
	}
}
</script>
